import apiInstance from "./apiInstance";

const addIndividualMessageGroup = (userIds) => {
  return apiInstance.post(`/instantMessage/individual`, userIds);
};

const addMessageGroup = (userIds) => {
  return apiInstance.post(`/instantMessage/group`, userIds);
};

const deleteMessageGroup = (instantMessageGroupId) => {
  return apiInstance.delete(`/instantMessage/${instantMessageGroupId}`);
};

const searchInstantMessageGroups = () => {
  return apiInstance.post(`/instantMessage/search`);
};

const getMessageMemberGroupCodes = () => {
  return apiInstance.get(`/instantMessage/memberCodes`);
};

const getMessageGroupUsers = (params) => {
  return apiInstance.post(`/instantMessage/users`, params);
};

const getMessageGroupUsersCount = (params) => {
  return apiInstance.post(`/instantMessage/users/count`, params);
};

const editMessageGroupNamePhoto = (params) => {
  return apiInstance.put(`/instantMessage/group/namePhoto`, params);
};
const editMessageGroupRoles = (params) => {
  return apiInstance.put(`/instantMessage/group/roles`, params);
};

const instantMessageGroupUserAddRemove = (params) => {
  return apiInstance.post(`/instantMessage/user/addremove`, params);
};

const instantMessageGroupAdminAddRemove = (params) => {
  return apiInstance.post(`/instantMessage/admin/addremove`, params);
};

const addInstantMessageConfig = (params) => {
  return apiInstance.post(`/instantMessageConfig`, params);
};

const editInstantMessageConfig = (params) => {
  return apiInstance.put(`/instantMessageConfig`, params);
};

const getSchoolInstantMessageConfig = (schoolId) => {
  return apiInstance.get(`/instantMessageConfig/${schoolId}`);
};

const sendNotifyToInstantMessageGroupUsers = (params) => {
  return apiInstance.post(`/instantMessage/notification`, params);
};

const addSingleUserToInstantMessageGroup = ({ groupId, userId }) => {
  return apiInstance.post(`/instantMessage/group/user/${groupId}/${userId}`);
};

export {
  addIndividualMessageGroup,
  searchInstantMessageGroups,
  addMessageGroup,
  getMessageMemberGroupCodes,
  getMessageGroupUsers,
  getMessageGroupUsersCount,
  editMessageGroupNamePhoto,
  instantMessageGroupUserAddRemove,
  instantMessageGroupAdminAddRemove,
  addInstantMessageConfig,
  editInstantMessageConfig,
  getSchoolInstantMessageConfig,
  deleteMessageGroup,
  editMessageGroupRoles,
  sendNotifyToInstantMessageGroupUsers,
  addSingleUserToInstantMessageGroup
};
