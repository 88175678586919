import { LayoutGroup } from "framer-motion";
import { TourProvider } from "@reactour/tour";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import lazyLoad from "helpers/lazyLoad";
import AnimatedPage from "routes/AnimatedPage";
import { tourSteps } from "constants/TourSteps";
import ProtectedRoute from "routes/ProtectedRoute";
import useUserAgreement from "hooks/useUserAgreement";
import { RoleTypeEnum } from "constants/enums/RoleEnum";
import { getAuthorizedRoutes } from "helpers/routeHelpers";
import { getStudentRoutes } from "helpers/StudentRouteHelpers";
import SplashScreen from "components/SplashScreen/SplashScreen";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { getSelectedStudent } from "store/slices/userStudentsSlice";
import { updateIsUserAgreementShown } from "store/slices/userSlice";
import ModuleSolution from "views/Common/ModuleSolution/ModuleSolution";
import InstantMessageProvider from "context/InstantMessageContext";
const StudentSideLayout = lazyLoad(
  () => import("layouts/StudentSideLayout/StudentSideLayout"),
  "StudentSideLayout"
);

const AdminSideLayout = React.lazy(() => import("layouts/AdminSideLayout/AdminSideLayout"));
const AboutUs = React.lazy(() => import("views/Common/Login/AboutUs"));
// const FlowArea = React.lazy(() => import("views/AdminSide/FlowArea/FlowArea"));
const ErrorPage1 = React.lazy(() => import("views/Common/ErrorPages/ErrorPage1"));
const PrivacyPolicy = React.lazy(() => import("views/Common/Login/PrivacyPolicy"));
const ResetPassword = React.lazy(() => import("views/Common/Login/ResetPassword"));
const PaymentCancel = React.lazy(() => import("views/Common/Login/PaymentCancel"));
const UserAgreement = React.lazy(() => import("views/Common/UserAgreement/UserAgreement"));
// const StudentDashboard = React.lazy(() => import("views/StudentSide/Dashboard/StudentDashboard"));
const DistanceSellingContract = React.lazy(() =>
  import("views/Common/Login/DistanceSellingContract")
);
const CheckUserPasswordReset = React.lazy(() =>
  import("views/Common/CheckUserPasswordReset/CheckUserPasswordReset")
);
const AuthorizationSelectorCard = React.lazy(() =>
  import("views/Common/AuthorizationSelector/AuthorizationSelectorCard")
);
const LoginPage = React.lazy(() => import("views/Common/Login/LoginPage"));
const Landing = React.lazy(() => import("views/Common/Landing/Landing"));
const RegisterForm = React.lazy(() => import("views/Common/Login/components/RegisterForm"));
const BigFive = React.lazy(() => import("views/Common/Login/BigFive"));

function App() {
  const dispatch = useDispatch();
  const { checkUserAgreementFromApi } = useUserAgreement({
    onCheckSuccess: (res) => {
      dispatch(updateIsUserAgreementShown(true));
      setShowUserAgreement(!res?.data);
    }
  });
  const [showUserAgreement, setShowUserAgreement] = useState(false);
  const user = useSelector((state) => state.user);
  const student = useSelector(getSelectedStudent);

  useEffect(() => {
    if (user?.identity?.identityNumber) {
      checkUserAgreementFromApi(user?.identity?.identityNumber);
    }
  }, [user?.identity?.identityNumber]);

  const getRoutes = () => {
    if (user.selectedAuthorization) {
      if (user.selectedAuthorization.roleType === RoleTypeEnum.STUDENT) {
        return (
          <Route path="/" element={<StudentSideLayout />}>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            {/* <Route path="dashboard" element={<StudentDashboard />} /> */}
            {getStudentRoutes(user.selectedAuthorization, user.clients, user.selectedAuthorization)
              .filter((menu) => menu.component)
              .map((menu) => {
                const MenuComponent = menu?.component;
                return (
                  <Route
                    key={menu.id}
                    path={menu.route}
                    element={
                      <React.Suspense key={menu.id} fallback={<SplashScreen />}>
                        <AnimatedPage>
                          <MenuComponent />
                        </AnimatedPage>
                      </React.Suspense>
                    }
                  />
                );
              })}
            <Route path="*" element={<ErrorPage1 />} />
          </Route>
        );
      } else if (user.selectedAuthorization.roleType === RoleTypeEnum.CONSERVATOR) {
        return (
          <Route path="/" element={<StudentSideLayout />}>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            {/* <Route path="dashboard" element={<StudentDashboard />} /> */}
            {getStudentRoutes(student?.studentDetails, user.clients, user.selectedAuthorization)
              .filter((menu) => menu.component)
              .map((menu) => {
                const MenuComponent = menu?.component;
                return (
                  <Route
                    key={menu.id}
                    path={menu.route}
                    element={
                      <React.Suspense key={menu.id} fallback={<SplashScreen />}>
                        <AnimatedPage>
                          <MenuComponent />
                        </AnimatedPage>
                      </React.Suspense>
                    }
                  />
                );
              })}
            <Route path="*" element={<ErrorPage1 />} />
          </Route>
        );
      } else {
        const authorizedRoutes = getAuthorizedRoutes(
          user?.selectedAuthorization?.operations,
          user?.selectedAuthorization?.roleType,
          user?.selectedAuthorization,
          user
        );
        return (
          <Route element={<AdminSideLayout />}>
            <Route path="/" element={<Navigate to="/dashboard" replace />} />
            {/* <Route path="/dashboard" element={<Dashboard />} /> */}
            {authorizedRoutes.map((menu) => {
              const MenuComponent = menu.component;
              return (
                <Route
                  key={menu.id}
                  path={menu.path}
                  element={
                    <React.Suspense key={menu.id} fallback={<SplashScreen />}>
                      <AnimatedPage>
                        <MenuComponent />
                      </AnimatedPage>
                    </React.Suspense>
                  }
                />
              );
            })}
            <Route path="*" element={<ErrorPage1 />} />
          </Route>
        );
      }
    } else {
      return null;
    }
  };

  return (
    <ErrorBoundary>
      <TourProvider steps={tourSteps}>
        <LayoutGroup type="crossfade">
          <InstantMessageProvider>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/cozum-videolari" element={<ModuleSolution />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register-form" element={<RegisterForm />} />
              <Route path="/big-five" element={<BigFive />} />
              <Route path="/register-form-izmir" element={<RegisterForm />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/distance-selling-contract" element={<DistanceSellingContract />} />
              <Route path="/payment-cancel" element={<PaymentCancel />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              {/* {!user.selectedAuthorization && <Route path="*" element={<Navigate to="/login" />} />} */}
              {!user.access_token && <Route path="*" element={<Navigate to="/" />} />}
              {user.access_token && user.roles && !user.selectedAuthorization && (
                <Route path="*" element={<Navigate to="/select-authorization" />} />
              )}
              <Route element={<ProtectedRoute />}>
                {/* yetki seçimi yapmış ve yinede yetki seçimi sayfasına yönlenmeye çalışırsa. TODO:  yönlendirmeyi öğrenciye göre control et*/}
                {user.selectedAuthorization ? (
                  <Route
                    path="/select-authorization"
                    element={<Navigate to="/dashboard" replace />}
                  />
                ) : (
                  <Route path="/select-authorization" element={<AuthorizationSelectorCard />} />
                )}

                {getRoutes()}
                {/* <Route path="student/*" element={<StudentLayout />} /> */}
              </Route>
              <Route path="*" element={<ErrorPage1 />} />
            </Routes>
          </InstantMessageProvider>
          {showUserAgreement && user?.identity?.identityNumber && (
            <UserAgreement
              show={showUserAgreement}
              onHide={() => setShowUserAgreement(false)}
              identityNumber={user?.identity?.identityNumber}
            />
          )}
          <CheckUserPasswordReset />
        </LayoutGroup>
      </TourProvider>
    </ErrorBoundary>
  );
}

export default App;

//CURRENT ROLE OPERATIONS
// if (
//   user &&
//   (user?.selectedAuthorization?.roleType === RoleTypeEnum.PERSONNEL ||
//     user?.selectedAuthorization?.roleType === RoleTypeEnum.CONSERVATOR) &&
//   !user?.selectedAuthorization?.operations
// ) {
//   await apiCall({
//     service: getRoleOperationList,
//     serviceParams: user?.selectedAuthorization?.id,
//     onSuccess: (response) => {
//       if (response?.data?.length > 0) {
//         dispatch(updateCurrentRoleOperationList(response.data));
//       }
//     }
//   });
// }
//END OF CURRENT ROLE OPERATIONS
