import axios from "axios";
import store from "store";
import { logout as logoutAction } from "store/slices/userSlice";
import showToast from "helpers/showToast";
import { RoleTypeEnum } from "constants/enums/RoleEnum";
// AXIOS INSTANCE
const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_API + process.env.REACT_APP_API_NAMESPACE,
  timeout: 300000,
  timeoutErrorMessage: "Servis çağrısı zaman aşımına uğradı tekrar deneyiniz!"
});

// REQUEST-INTERCEPTOR
apiInstance.interceptors.request.use(
  (config) => {
    const { user, term } = store.getState();
    if (user && user.access_token) {
      config.headers.Authorization = `Bearer ${user.access_token}`;
    }
    if (user && user.selectedAuthorization) {
      config.headers.ROLE_ID = user.selectedAuthorization?.id;
    }
    if (term && term.selectedTerm) {
      config.headers.TERM_ID = term.selectedTerm?.id;
    } else if (
      user?.selectedAuthorization?.roleType === RoleTypeEnum.STUDENT &&
      user?.selectedAuthorization?.details?.termInfo?.id
    ) {
      config.headers.TERM_ID = user.selectedAuthorization?.details?.termInfo?.id;
    } else if (
      user?.selectedAuthorization?.roleType === RoleTypeEnum.CONSERVATOR &&
      user?.selectedAuthorization?.permCode?.split("-")?.length > 0
    ) {
      config.headers.TERM_ID = user?.selectedAuthorization?.permCode?.split("-")[0];
    }
    // TODO : kurum ağacı permcode bilgisi gönderilecek
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// RESPONSE-INTERCEPTOR
apiInstance.interceptors.response.use(
  (resp) => {
    return resp.data;
  },
  (err) => {
    if (err.response.status === 401) {
      store.dispatch(logoutAction());
    }
    if (err.response.status === 417) {
      showToast(
        "MyExamy erişiminiz kısıtlandı. Platformu kullanmaya devam etmek için kurumunuzla iletişime geçin.",
        { type: "error" }
      );
      store.dispatch(logoutAction());
    }
    if (err.response.status === 403) {
      if (err?.request?.responseURL?.includes("userv2/user/dashboard")) return false;
      showToast(`Yetkisiz işlem ${err.response.data.message}`, { type: "error" });
    }
    throw err;
  }
);

export default apiInstance;
