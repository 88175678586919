const firebaseConfig = {
  apiKey: "AIzaSyBAcLEbMbMtngz4dT1xyF8wvvGNiBKSGCo",
  authDomain: "myexamy-fcm.firebaseapp.com",
  databaseURL: "https://myexamy-fcm-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "myexamy-fcm",
  storageBucket: "myexamy-fcm.appspot.com",
  messagingSenderId: "719339219828",
  appId: "1:719339219828:web:7afbfbee9b094671ce5a21",
  measurementId: "G-HD7C6ZTDPR"
};

export { firebaseConfig };
